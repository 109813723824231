/* -- FRONT CONTAINER -- */
/* ---------------------------------------------- */

.front-container {
    background-color: var( --#{ $prefix }body-bg );
    color: var( --#{ $prefix }body-color );
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr min-content;

    .header { grid-area: 1 / 1 / 2 / 2; }

    .content {
        background-color: transparent;
        color: inherit;
        grid-area: 2 / 1 / 3 / 2;
    }

    .footer {
        grid-area: 3 / 1 / 4 / 2;
    }

    @at-root {
        body[ style*="background" ]:not( .boxed-layout ) > #{ & }, .bg-img:not( .boxed-layout ) > #{ & } {
            background-color: rgba( var(--#{$prefix}body-bg-rgb), 0.5);
            background-attachment: fixed;
            background-blend-mode: screen;
        }
    }
}
