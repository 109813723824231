// VARIABLES
// ---------------------------------------------------------------------------------

// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


// HELPERS
// ---------------------------------------------------------------------------------
$utilities: ();


// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   2.05 !default;


// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark:      $black !default;
$color-contrast-light:     $white !default;


// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29"),
) !default;



// Options
// ---------------------------------------------------------------------------------
// Quickly modify global styling by enabling or disabling optional features.
$enable-caret:                true !default;
$enable-rounded:              true !default;
$enable-shadows:              true !default;
$enable-gradients:            false !default;
$enable-transitions:          true !default;
$enable-reduced-motion:       true !default;
$enable-smooth-scroll:        true !default;
$enable-grid-classes:         true !default;
$enable-container-classes:    true !default;
$enable-cssgrid:              false !default;
$enable-button-pointers:      true !default;
$enable-rfs:                  true !default;
$enable-validation-icons:     true !default;
$enable-negative-margins:     false !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities:  true !default;

$enable-dark-mode:            true !default;
$color-mode-type:             data !default; // `data` or `media-query`



// Prefix for :root CSS variables
// ---------------------------------------------------------------------------------
$prefix:                      bs- !default;



// Gradient
// ---------------------------------------------------------------------------------
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
$gradient: linear-gradient(180deg, rgba($white, .35), rgba($white, 0)) !default;



// Spacing
// ---------------------------------------------------------------------------------
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;



// Position
// ---------------------------------------------------------------------------------
// Define the edge positioning anchors of the position utilities.
$position-values: (
  0: 0,
  50: 50%,
  100: 100%
) !default;



// Body
// ---------------------------------------------------------------------------------
// Settings for the `<body>` element.
$body-text-align:           null !default;
$body-color:                #75868f !default;
$body-bg:                   #edf1f6 !default;

$body-secondary-color:      rgba($body-color, .75) !default;
$body-secondary-bg:         shade-color($body-bg, 5%) !default;

$body-tertiary-color:       rgba($body-color, .5) !default;
$body-tertiary-bg:          shade-color($body-bg, 10%) !default;

$body-emphasis-color:       $black !default;



// Links
// --------------------------------------------------------------------------------------------------------------------
// Style anchor elements.
$link-color:                              $blue !default;
$link-decoration:                         underline !default;
$link-shade-percentage:                   25% !default;
$link-hover-color:                        tint-color($blue, 25%) !default; //shift-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration:                   null !default;

$stretched-link-pseudo-element:           after !default;
$stretched-link-z-index:                  1 !default;



// Icon links
// ---------------------------------------------------------------------------------
$icon-link-gap:                           .375rem !default;
$icon-link-underline-offset:              .25em !default;
$icon-link-icon-size:                     1em !default;
$icon-link-icon-transition:               .2s ease-in-out transform !default;
$icon-link-icon-transform:                translate3d(.25em, 0, 0) !default;



// Paragraphs
// ---------------------------------------------------------------------------------
// Style p element.
$paragraph-margin-bottom:                 1rem !default;



// Grid breakpoints
// ---------------------------------------------------------------------------------
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;


//@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
//@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");



// Grid containers
// ---------------------------------------------------------------------------------
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;
//@include _assert-ascending($container-max-widths, "$container-max-widths");



// Grid columns
// ---------------------------------------------------------------------------------
// Set the number of columns and specify the width of the gutters.
$grid-columns:                12 !default;
$grid-gutter-width:           1rem !default;
$grid-row-columns:            6 !default;



// Container padding
// ---------------------------------------------------------------------------------
$container-padding-x:         $grid-gutter-width !default;


// Components
// ---------------------------------------------------------------------------------
// Define common padding and border radius sizes and more.
$border-width:                1px !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
) !default;
$border-style:                solid !default;
$border-color:                rgba($black, .1) !default;
$border-color-translucent:    rgba($black, .175) !default;


$border-radius:               .375rem !default;
$border-radius-sm:            .25rem !default;
$border-radius-lg:            .75rem !default;
$border-radius-xl:            1rem !default;
$border-radius-xxl:           2rem !default;
$border-radius-pill:          50rem !default;

$border-radius-2xl:           $border-radius-xxl !default; // Deprecated in v5.3.0


$box-shadow-color:            to-rgb(#1b1741) !default;
$box-shadow-alpha:            .25 !default;
$box-shadow-alpha-xs:         .075 !default;
$box-shadow-alpha-sm:         .125 !default;
$box-shadow-alpha-lg:         .25 !default;
$box-shadow-alpha-inset:      .1 !default;


$box-shadow:                  0 1rem 1rem -.75rem rgba(var(--#{$prefix}box-shadow-color), var(--#{$prefix}box-shadow-alpha)) !default;
$box-shadow-sm:               0 .125rem .25rem rgba(var(--#{$prefix}box-shadow-color), var(--#{$prefix}box-shadow-alpha-sm)) !default;
$box-shadow-lg:               0 .1rem .25rem rgba(var(--#{$prefix}box-shadow-color), calc(var(--#{$prefix}box-shadow-alpha-lg)/1.5)), 0 .125rem 2rem rgba(var(--#{$prefix}box-shadow-color), var(--#{$prefix}box-shadow-alpha-lg)) !default;
$box-shadow-inset:            inset 0 1px 2px rgba(var(--#{$prefix}box-shadow-color), var(--#{$prefix}box-shadow-alpha-inset)) !default;


$component-bg:                $white !default;
$component-color:             tint-color( $dark, 15% ) !default;
$component-active-bg:         var(--#{$prefix}primary) !default;
$component-active-color:      var(--#{$prefix}primary-color) !default;
$component-hover-bg:          $body-tertiary-bg !default;
$component-hover-color:       $body-emphasis-color !default;


$component-alt-bg:            rgba( 147, 147, 147, .5 ) !default;
$component-alt-active-bg:     rgba( 27, 29, 33, .5 ) !default;


$focus-ring-width:            .2rem !default;
$focus-ring-opacity:          .25 !default;
$focus-ring-color:            rgba(var(--#{$prefix}primary-rgb), $focus-ring-opacity) !default;
$focus-ring-blur:             .25rem !default;
$focus-ring-box-shadow:       0 0 $focus-ring-blur $focus-ring-width $focus-ring-color !default;


$caret-width:                 .3em !default;
$caret-vertical-align:        $caret-width * .85 !default;
$caret-spacing:               $caret-width * .85 !default;


$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;

$transition-collapse:         height .35s ease !default;
$transition-collapse-width:   width .35s ease !default;


$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
) !default;



// Typography
// ---------------------------------------------------------------------------------
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif:      "Poppins", "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

$font-family-base:            var(--#{$prefix}font-sans-serif) !default;
$font-family-code:            var(--#{$prefix}font-monospace) !default;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root:              null !default;
$font-size-base:              .75rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875 !default;
$font-size-lg:                $font-size-base * 1.25 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-medium:          500 !default;
$font-weight-semibold:        600 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;
$font-weight-base:            $font-weight-normal !default;

$line-height-base:            1.5 !default;
$line-height-sm:              1.25 !default;
$line-height-lg:              2 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;


$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
) !default;


// Headings-variables
// ---------------------------------------------------------------------------------
$headings-margin-bottom:      $spacer * .5 !default;
$headings-font-family:        "Ubuntu" !default;
$headings-font-style:         null !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.2 !default;
$headings-color:              $dark !default;


$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem
) !default;

$display-font-family:         null !default;
$display-font-style:          null !default;
$display-font-weight:         300 !default;
$display-line-height:         $headings-line-height !default;


$lead-font-size:              $font-size-base * 1.25 !default;
$lead-font-weight:            400 !default;

$small-font-size:             .875em !default;
$sub-sup-font-size:           .75em !default;
$text-muted:                  opaque( $body-bg, rgba($body-color, .6) ) !default; // Deprecated in 5.3.0

$initialism-font-size:        $small-font-size !default;

$blockquote-margin-y:         $spacer !default;
$blockquote-font-size:        $font-size-base * 1.25 !default;
$blockquote-footer-color:     $text-muted !default;
$blockquote-footer-font-size: $small-font-size !default;

$hr-margin-y:                 $spacer !default;
$hr-color:                    inherit !default;

$hr-bg-color:                 null !default; // Deprecated in v5.2.0
$hr-height:                   null !default; // Deprecated in v5.2.0

$hr-border-color:             null !default; // Allows for inherited colors
$hr-border-width:             var(--#{$prefix}border-width) !default;
$hr-opacity:                  .25 !default;

$vr-border-width:             var(--#{$prefix}border-width) !default;

$legend-margin-bottom:        .5rem !default;
$legend-font-size:            1.5rem !default;
$legend-font-weight:          null !default;

$dt-font-weight:              $font-weight-bold !default;

$list-inline-padding:         .5rem !default;

$mark-padding:                .1875em !default;
$mark-color:                  $body-color !default;
$mark-bg:                     $yellow-100 !default;



// Tables
// ---------------------------------------------------------------------------------
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding-y:        .75rem !default;
$table-cell-padding-x:        .5rem !default;
$table-cell-padding-y-sm:     .5rem !default;
$table-cell-padding-x-sm:     .25rem !default;

$table-cell-vertical-align:   top !default;

$table-color:                 var(--#{$prefix}emphasis-color) !default;
$table-bg:                    transparent !default;
$table-accent-bg:             transparent !default;

$table-th-font-weight:        $font-weight-semibold !default;

$table-striped-color:         $table-color !default;
$table-striped-bg-factor:     .035 !default;
$table-striped-bg:            rgba(var(--#{$prefix}emphasis-color-rgb), $table-striped-bg-factor) !default;

$table-active-color:          $table-color !default;
$table-active-bg-factor:      .2 !default;
$table-active-bg:             rgba(var(--#{$prefix}primary-rgb), $table-active-bg-factor) !default;

$table-hover-color:           $table-color !default;
$table-hover-bg-factor:       .075 !default;
$table-hover-bg:              rgba(var(--#{$prefix}emphasis-color-rgb), $table-hover-bg-factor) !default;

$table-border-factor:         .07 !default;
$table-border-width:          var(--#{$prefix}border-width) !default;
$table-border-color:          rgba(var(--#{$prefix}emphasis-color-rgb), $table-border-factor) !default;
$table-bordered-factor:       .17 !default;
$table-bordered-color:        rgba(var(--#{$prefix}emphasis-color-rgb), $table-bordered-factor) !default;


$table-striped-order:         odd !default;
$table-striped-columns-order: even !default;

$table-group-separator-color: currentcolor !default;

$table-caption-color:         var(--#{$prefix}secondary-color) !default;

$table-bg-scale:              -80% !default;

$table-variants: (
  "primary":    shift-color($primary, $table-bg-scale),
  "secondary":  shift-color($secondary, $table-bg-scale),
  "success":    shift-color($success, $table-bg-scale),
  "info":       shift-color($info, $table-bg-scale),
  "warning":    shift-color($warning, $table-bg-scale),
  "danger":     shift-color($danger, $table-bg-scale),
  "light":      $light,
  "dark":       $dark,
) !default;

$table-spaced-spacing:        .35rem !default;
$table-spaced-color:          $table-color !default;
$table-spaced-bg-factor:      .05 !default;
$table-spaced-bg:             rgba(var(--#{$prefix}emphasis-color-rgb), $table-spaced-bg-factor) !default;
$table-spaced-border-radius:  var(--#{$prefix}border-radius) !default;



// Buttons + Forms
// ---------------------------------------------------------------------------------
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y:         .55rem !default;
$input-btn-padding-x:         1rem !default;
$input-btn-font-family:       null !default;
$input-btn-font-size:         $font-size-base !default;
$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:         $focus-ring-width !default;
$input-btn-focus-color-opacity: $focus-ring-opacity !default;
$input-btn-focus-color:         $focus-ring-color !default;
$input-btn-focus-blur:          $focus-ring-blur !default;
$input-btn-focus-box-shadow:    $focus-ring-box-shadow !default;

$input-btn-padding-y-sm:      .45rem !default;
$input-btn-padding-x-sm:      .95rem !default;
$input-btn-font-size-sm:      $font-size-sm !default;

$input-btn-padding-y-lg:      .75rem !default;
$input-btn-padding-x-lg:      1.2rem !default;
$input-btn-font-size-lg:      $font-size-lg !default;

$input-btn-border-width:      var(--#{$prefix}border-width) !default;



// Buttons
// ---------------------------------------------------------------------------------
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-color:                   var(--#{$prefix}body-color) !default;
$btn-padding-y:               $input-btn-padding-y !default;
$btn-padding-x:               $input-btn-padding-x !default;
$btn-font-family:             $input-btn-font-family !default;
$btn-font-size:               $input-btn-font-size !default;
$btn-line-height:             $input-btn-line-height !default;
$btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-xs:            .15rem !default;
$btn-padding-x-xs:            .35rem !default;
$btn-font-size-xs:            $font-size-sm !default;

$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
$btn-font-size-sm:            $input-btn-font-size-sm !default;

$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-font-size-lg:            $input-btn-font-size-lg !default;

$btn-border-width:            $input-btn-border-width !default;

$btn-font-weight:             $font-weight-normal !default;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-focus-width:             $input-btn-focus-width !default;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        .45 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

$btn-link-color:              var(--#{$prefix}link-color) !default;
$btn-link-hover-color:        var(--#{$prefix}link-hover-color) !default;
$btn-link-disabled-color:     $gray-600 !default;
$btn-link-focus-shadow-rgb:   to-rgb(mix(color-contrast($link-color), $link-color, 15%)) !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           var(--#{$prefix}border-radius) !default;
$btn-border-radius-sm:        var(--#{$prefix}border-radius-sm) !default;
$btn-border-radius-lg:        var(--#{$prefix}border-radius-lg) !default;

$btn-transition:              color .25s ease-in-out, background-color .25s ease-in-out, border-color .25s ease-in-out, box-shadow .25s ease-in-out, transform .025s ease-in-out !default;

$btn-hover-bg-shade-amount:       15% !default;
$btn-hover-bg-tint-amount:        15% !default;
$btn-hover-border-shade-amount:   20% !default;
$btn-hover-border-tint-amount:    10% !default;
$btn-hover-translate:             translateY(-1px) !default;
$btn-active-bg-shade-amount:      30% !default;
$btn-active-bg-tint-amount:       20% !default;
$btn-active-border-shade-amount:  25% !default;
$btn-active-border-tint-amount:   10% !default;



// Forms
// ---------------------------------------------------------------------------------
$form-text-margin-top:                  .25rem !default;
$form-text-font-size:                   $small-font-size !default;
$form-text-font-style:                  null !default;
$form-text-font-weight:                 null !default;
$form-text-color:                       var(--#{$prefix}secondary-color) !default;


$form-label-margin-bottom:              .5rem !default;
$form-label-font-size:                  null !default;
$form-label-font-style:                 null !default;
$form-label-font-weight:                null !default;
$form-label-color:                      var(--#{$prefix}emphasis-color) !default;


// Input
// ---------------------------------------------------------------------------------
$input-padding-y:                       $input-btn-padding-y !default;
$input-padding-x:                       $input-btn-padding-x !default;
$input-font-family:                     $input-btn-font-family !default;
$input-font-size:                       $input-btn-font-size !default;
$input-font-weight:                     $font-weight-base !default;
$input-line-height:                     $input-btn-line-height !default;

$input-padding-y-sm:                    $input-btn-padding-y-sm !default;
$input-padding-x-sm:                    $input-btn-padding-x-sm !default;
$input-font-size-sm:                    $input-btn-font-size-sm !default;

$input-padding-y-lg:                    $input-btn-padding-y-lg !default;
$input-padding-x-lg:                    $input-btn-padding-x-lg !default;
$input-font-size-lg:                    $input-btn-font-size-lg !default;

$input-bg:                              var(--#{$prefix}component-bg) !default;
$input-disabled-color:                  null !default;
$input-disabled-bg:                     rgba(var(--#{$prefix}emphasis-color-rgb), .075) !default;
$input-disabled-border-color:           null !default;
$input-disabled-opacity:                .5 !default;



$input-color:                           var(--#{$prefix}emphasis-color) !default;
$input-border-color:                    var(--#{$prefix}border-color) !default;
$input-border-width:                    $input-btn-border-width !default;
$input-box-shadow:                      inset 0 0 0 transparent !default;

$input-border-radius:                   var(--#{$prefix}border-radius) !default;
$input-border-radius-sm:                var(--#{$prefix}border-radius-sm) !default;
$input-border-radius-lg:                var(--#{$prefix}border-radius-lg) !default;

$input-focus-bg:                        $input-bg !default;
$input-focus-border-color:              $component-active-bg !default;
$input-focus-color:                     $input-color !default;
$input-focus-width:                     $input-btn-focus-width !default;
$input-focus-box-shadow:                0 0 .75rem 0 rgba(0,0,0,.15) !default;

$input-placeholder-color:               var(--#{$prefix}secondary-color) !default;
$input-plaintext-color:                 var(--#{$prefix}body-color) !default;

$input-height-border:                   calc(#{$input-border-width} * 2) !default; // stylelint-disable-line function-disallowed-list

$input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half:               add($input-line-height * .5em, $input-padding-y) !default;
$input-height-inner-quarter:            add($input-line-height * .25em, $input-padding-y * .5) !default;

$input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm:                       add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg:                       add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

$input-transition:                      border-color .35s ease-in-out, box-shadow .35s ease-in-out !default;

$form-color-width:                      3rem !default;


// Form check
// ---------------------------------------------------------------------------------
$form-check-input-width:                  1.25em !default;
$form-check-min-height:                   $font-size-base * $line-height-base !default;
$form-check-padding-start:                $form-check-input-width + .5em !default;
$form-check-margin-bottom:                .125rem !default;
$form-check-label-color:                  null !default;
$form-check-label-cursor:                 null !default;
$form-check-transition:                   box-shadow .35s !default;

$form-check-input-active-filter:          brightness(90%) !default;

$form-check-input-bg:                     $input-bg !default;
$form-check-input-border:                 var(--#{$prefix}border-width) solid var(--#{$prefix}border-color) !default;
$form-check-input-border-radius:          .325em !default;
$form-check-radio-border-radius:          50% !default;
$form-check-input-focus-border:           $input-focus-border-color !default;
$form-check-input-focus-box-shadow:       $focus-ring-box-shadow !default;

$form-check-input-checked-color:          $white !default;
$form-check-input-checked-bg-color:       $component-active-bg !default;
$form-check-input-checked-border-color:   $form-check-input-checked-bg-color !default;
$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>") !default;
$form-check-radio-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;

$form-check-input-indeterminate-color:          $component-active-color !default;
$form-check-input-indeterminate-bg-color:       $component-active-bg !default;
$form-check-input-indeterminate-border-color:   $form-check-input-indeterminate-bg-color !default;
$form-check-input-indeterminate-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;

$form-check-input-disabled-opacity:        .5 !default;
$form-check-label-disabled-opacity:        .5 !default;
$form-check-btn-check-disabled-opacity:    $btn-disabled-opacity !default;

$form-check-inline-margin-end:    1rem !default;



// Form switch
// ---------------------------------------------------------------------------------
$form-switch-color:               $white !default;
$form-switch-width:               2.5em !default;
$form-switch-height:              1.35em !default;
$form-switch-padding-start:       1.35em !default;
$form-switch-border-color:        var(--#{$prefix}tertiary-color) !default;
$form-switch-bg-color:            var(--#{$prefix}tertiary-color) !default;
$form-switch-bg-image:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{ $form-switch-color }'/></svg>") !default;
$form-switch-border-radius:       $form-switch-width !default;
$form-switch-transition:          background-color .25s ease-in-out, background-position .25s ease-in-out !default;

$form-switch-focus-color:         $white !default;
$form-switch-focus-bg-image:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{ $form-switch-focus-color }'/></svg>") !default;

$form-switch-checked-color:       $white !default;
$form-switch-checked-bg-image:    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{ $form-switch-checked-color }'/></svg>") !default;
$form-switch-checked-bg-position: right center !default;



// Input group
// ---------------------------------------------------------------------------------
$input-group-addon-padding-y:           $input-padding-y !default;
$input-group-addon-padding-x:           $input-padding-x !default;
$input-group-addon-font-weight:         $input-font-weight !default;
$input-group-addon-color:               $input-color !default;
$input-group-addon-bg:                  rgba(var(--#{$prefix}tertiary-bg-rgb), .1) !default;
$input-group-addon-border-color:        $input-border-color !default;



// Select
// ---------------------------------------------------------------------------------
$form-select-padding-y:             $input-padding-y !default;
$form-select-padding-x:             $input-padding-x !default;
$form-select-font-family:           $input-font-family !default;
$form-select-font-size:             $input-font-size !default;
$form-select-indicator-padding:     $form-select-padding-x * 3 !default; // Extra padding for background-image
$form-select-font-weight:           $input-font-weight !default;
$form-select-line-height:           $input-line-height !default;
$form-select-color:                 $input-color !default;
$form-select-bg:                    var(--#{$prefix}component-bg) !default;
$form-select-disabled-color:        null !default;
$form-select-disabled-bg:           $input-disabled-bg !default;
$form-select-disabled-border-color: $input-disabled-border-color !default;
$form-select-bg-position:           right $form-select-padding-x center !default;
$form-select-bg-size:               16px 12px !default; // In pixels because image dimensions
$form-select-indicator-color:       $gray-800 !default;
$form-select-indicator:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>") !default;

$form-select-feedback-icon-padding-end: $form-select-padding-x * 2.5 + $form-select-indicator-padding !default;
$form-select-feedback-icon-position:    center right $form-select-indicator-padding !default;
$form-select-feedback-icon-size:        $input-height-inner-half $input-height-inner-half !default;

$form-select-border-width:        $input-border-width !default;
$form-select-border-color:        $input-border-color !default;
$form-select-border-radius:       $input-border-radius !default;
$form-select-box-shadow:          inset 0 0 0 transparent !default;

$form-select-focus-border-color:  $input-focus-border-color !default;
$form-select-focus-width:         $input-focus-width !default;
$form-select-focus-box-shadow:    $input-focus-box-shadow !default;

$form-select-padding-y-sm:        $input-padding-y-sm !default;
$form-select-padding-x-sm:        $input-padding-x-sm !default;
$form-select-font-size-sm:        $input-font-size-sm !default;
$form-select-border-radius-sm:    $input-border-radius-sm !default;

$form-select-padding-y-lg:        $input-padding-y-lg !default;
$form-select-padding-x-lg:        $input-padding-x-lg !default;
$form-select-font-size-lg:        $input-font-size-lg !default;
$form-select-border-radius-lg:    $input-border-radius-lg !default;

$form-select-transition:          $input-transition !default;



// Range
// ---------------------------------------------------------------------------------
$form-range-track-width:          100% !default;
$form-range-track-height:         .25rem !default;
$form-range-track-cursor:         pointer !default;
$form-range-track-bg:             var(--#{$prefix}secondary-bg) !default;
$form-range-track-border-radius:  1rem !default;
$form-range-track-box-shadow:     var(--#{$prefix}box-shadow-inset) !default;

$form-range-thumb-width:                   .85rem !default;
$form-range-thumb-height:                  $form-range-thumb-width !default;
$form-range-thumb-bg:                      $component-active-bg !default;
$form-range-thumb-border:                  0 !default;
$form-range-thumb-border-radius:           1rem !default;
$form-range-thumb-box-shadow:              0 0 0 3px var(--#{$prefix}component-bg), 0 0 .75rem var(--#{$prefix}primary) !default;
$form-range-thumb-focus-box-shadow:        0 0 0 2px var(--#{$prefix}component-bg), 0 0 1rem var(--#{$prefix}primary) !default;
$form-range-thumb-focus-box-shadow-width:  $input-focus-width !default; // For focus box shadow issue in Edge
$form-range-thumb-active-bg:               var(--#{$prefix}form-range-thumb-active-bg) !default;
$form-range-thumb-disabled-bg:             var(--#{$prefix}secondary-color) !default;
$form-range-thumb-transition:              background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;



// File
// ---------------------------------------------------------------------------------
$form-file-button-color:          $input-color !default;
$form-file-button-bg:             rgba(var(--#{$prefix}tertiary-bg-rgb), .35) !default;
$form-file-button-hover-bg:       var(--#{$prefix}secondary-bg) !default;



// Form floating
// ---------------------------------------------------------------------------------
$form-floating-height:                  add(3.5rem, $input-height-border) !default;
$form-floating-line-height:             1.25 !default;
$form-floating-padding-x:               $input-padding-x !default;
$form-floating-padding-y:               1rem !default;
$form-floating-input-padding-t:         1.625rem !default;
$form-floating-input-padding-b:         .625rem !default;
$form-floating-label-height:            1.5em !default;
$form-floating-label-opacity:           .65 !default;
$form-floating-label-transform:         scale(.85) translateY(-.5rem) translateX(.15rem) !default;
$form-floating-label-disabled-color:    rgba(var(--#{$prefix}body-color-rgb), #{$form-floating-label-opacity}) !default;
$form-floating-transition:              opacity .1s ease-in-out, transform .1s ease-in-out !default;



// Form validation
// ---------------------------------------------------------------------------------
$form-feedback-margin-top:          $form-text-margin-top !default;
$form-feedback-font-size:           $form-text-font-size !default;
$form-feedback-font-style:          $form-text-font-style !default;
$form-feedback-valid-color:         $success !default;
$form-feedback-invalid-color:       $danger !default;

$form-feedback-icon-valid-color:    $form-feedback-valid-color !default;
$form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color !default;
$form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;

$form-valid-color:                  $form-feedback-valid-color !default;
$form-valid-border-color:           $form-feedback-valid-color !default;
$form-invalid-color:                $form-feedback-invalid-color !default;
$form-invalid-border-color:         $form-feedback-invalid-color !default;

$form-validation-states: (
  "valid": (
    "color": var(--#{$prefix}form-valid-color),
    "icon": $form-feedback-icon-valid,
    "tooltip-color": #fff,
    "tooltip-bg-color": var(--#{$prefix}success),
    "focus-box-shadow": 0 0 $input-btn-focus-blur $input-focus-width rgba(var(--#{$prefix}success-rgb), $input-btn-focus-color-opacity),
    "border-color": var(--#{$prefix}form-valid-border-color),
  ),
  "invalid": (
    "color": var(--#{$prefix}form-invalid-color),
    "icon": $form-feedback-icon-invalid,
    "tooltip-color": #fff,
    "tooltip-bg-color": var(--#{$prefix}danger),
    "focus-box-shadow": 0 0 $input-btn-focus-blur $input-focus-width rgba(var(--#{$prefix}danger-rgb), $input-btn-focus-color-opacity),
    "border-color": var(--#{$prefix}form-invalid-border-color),
  )
) !default;



// Z-index master list
// ---------------------------------------------------------------------------------
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-offcanvas-backdrop:         1040 !default;
$zindex-offcanvas:                  1045 !default;
$zindex-modal-backdrop:             1050 !default;
$zindex-modal:                      1055 !default;
$zindex-popover:                    1070 !default;
$zindex-tooltip:                    1080 !default;
$zindex-toast:                      1090 !default;

$zindex-levels: (
  n1: -1,
  0: 0,
  1: 1,
  2: 2,
  3: 3
) !default;




// Navs
// ---------------------------------------------------------------------------------
$nav-link-padding-y:                .5rem !default;
$nav-link-padding-x:                1.25rem !default;
$nav-link-font-size:                null !default;
$nav-link-font-weight:              null !default;
$nav-link-color:                    var(--#{$prefix}link-color) !default;
$nav-link-hover-color:              var(--#{$prefix}link-hover-color) !default;
$nav-link-transition:               color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out !default;
$nav-link-disabled-color:           rgba(var(--#{$prefix}secondary-color-rgb), .5) !default;
$nav-link-focus-box-shadow:         $focus-ring-box-shadow !default;

$nav-tabs-link-color:               var(--#{$prefix}body-color) !default;
$nav-tabs-link-bg:                  rgba(var(--#{$prefix}emphasis-color-rgb), .05) !default;
$nav-tabs-link-bg-hover:            rgba(var(--#{$prefix}emphasis-color-rgb), .075) !default;
$nav-tabs-link-bg-disabled:         rgba(var(--#{$prefix}emphasis-color-rgb), .05) !default;

$nav-tabs-border-color:             var(--#{$prefix}border-color) !default;
$nav-tabs-border-width:             var(--#{$prefix}border-width) !default;
$nav-tabs-border-radius:            var(--#{$prefix}border-radius) !default;
$nav-tabs-padding:                  #{ $spacer + .25 } !default;
$nav-tabs-link-hover-border-color:  transparent !default;
$nav-tabs-link-active-color:        var(--#{$prefix}emphasis-color) !default;
$nav-tabs-link-active-bg:           var(--#{$prefix}component-bg) !default;
$nav-tabs-link-active-border-color: var(--#{$prefix}border-color-translucent) !default;

$nav-pills-border-radius:           var(--#{$prefix}border-radius) !default;
$nav-pills-link-color:              $nav-tabs-link-color !default;
$nav-pills-link-hover-color:        var(--#{$prefix}heading-color) !default;
$nav-pills-link-active-color:       $component-active-color !default;
$nav-pills-link-active-bg:          $component-active-bg !default;

$nav-underline-gap:                 .0625rem !default;
$nav-underline-border-width:        .1875rem !default;
$nav-underline-link-active-color:   var(--#{$prefix}emphasis-color) !default;



// Navbar
// ---------------------------------------------------------------------------------
$navbar-padding-y:                  $spacer * .5 !default;
$navbar-padding-x:                  null !default;

$navbar-nav-link-padding-x:         .5rem !default;

$navbar-brand-font-size:            $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height:                   2.5rem !default;
$navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) * .5 !default;
$navbar-brand-margin-end:           1rem !default;

$navbar-toggler-padding-y:          .25rem !default;
$navbar-toggler-padding-x:          .75rem !default;
$navbar-toggler-font-size:          $font-size-lg !default;
$navbar-toggler-border-radius:      $btn-border-radius !default;
$navbar-toggler-focus-width:        $btn-focus-width !default;
$navbar-toggler-transition:         box-shadow .15s ease-in-out !default;

$navbar-light-color:                rgba(var(--#{$prefix}emphasis-color-rgb), .65) !default;
$navbar-light-hover-color:          rgba(var(--#{$prefix}emphasis-color-rgb), .8) !default;
$navbar-light-active-color:         rgba(var(--#{$prefix}emphasis-color-rgb), 1) !default;
$navbar-light-disabled-color:       rgba(var(--#{$prefix}emphasis-color-rgb), .3) !default;
$navbar-light-icon-color:           rgba($body-color, .75) !default;
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba(var(--#{$prefix}emphasis-color-rgb), .15) !default;
$navbar-light-brand-color:          $navbar-light-active-color !default;
$navbar-light-brand-hover-color:    $navbar-light-active-color !default;


$navbar-dark-color:                 rgba($white, .55) !default;
$navbar-dark-hover-color:           rgba($white, .75) !default;
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, .25) !default;
$navbar-dark-icon-color:            $navbar-dark-color !default;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color:  rgba($white, .1) !default;
$navbar-dark-brand-color:           $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color:     $navbar-dark-active-color !default;



// Dropdowns
// ---------------------------------------------------------------------------------
// Dropdown menu container and contents.
$dropdown-animations:               true;
$dropdown-use-custom-js:            true;

$dropdown-min-width:                10rem !default;
$dropdown-padding-x:                .5rem !default;
$dropdown-padding-y:                .5rem !default;
$dropdown-spacer:                   .125rem !default;
$dropdown-font-size:                $font-size-base !default;
$dropdown-color:                    var(--#{$prefix}body-color) !default;
$dropdown-bg:                       var(--#{$prefix}component-bg) !default;
$dropdown-border-color:             transparent !default;
$dropdown-border-radius:            var(--#{$prefix}border-radius) !default;
$dropdown-border-width:             0 !default;
$dropdown-inner-border-radius:      calc(#{$dropdown-border-radius} - #{$dropdown-border-width}) !default; // stylelint-disable-line function-disallowed-list
$dropdown-divider-bg:               var(--#{$prefix}border-color) !default;
$dropdown-divider-margin-y:         $spacer * .5 !default;
$dropdown-box-shadow:               var(--#{$prefix}box-shadow-lg) !default;

$dropdown-link-color:               var(--#{$prefix}body-color) !default;
$dropdown-link-hover-color:         var(--#{$prefix}link-hover-color) !default;
$dropdown-link-hover-bg:            rgba(var(--#{$prefix}body-bg-rgb), .75) !default;

$dropdown-link-active-color:        $component-active-color !default;
$dropdown-link-active-bg:           $component-active-bg !default;

$dropdown-link-disabled-color:      var(--#{$prefix}tertiary-color) !default;

$dropdown-item-padding-y:           $spacer * .4 !default;
$dropdown-item-padding-x:           $spacer - $dropdown-padding-x !default;

$dropdown-header-color:             $gray-600 !default;
$dropdown-header-padding-x:         $dropdown-item-padding-x !default;
$dropdown-header-padding-y:         $dropdown-padding-y !default;

$dropdown-header-padding:           $dropdown-header-padding-y $dropdown-header-padding-x !default; // Deprecated in v5.2.0

$dropdown-transition-in:            .35s !default;
$dropdown-transition-out:           $dropdown-transition-in !default;
$dropdown-start-gap:                2rem !default;
$dropdown-transition-timing-in:     ease !default;
$dropdown-transition-timing-out:    ease !default;

$dropdown-dark-color:               $gray-300 !default;
$dropdown-dark-bg:                  $dark !default;
$dropdown-dark-border-color:        transparent !default;
$dropdown-dark-divider-bg:          $dropdown-divider-bg !default;
$dropdown-dark-box-shadow:          null !default;
$dropdown-dark-link-color:          $dropdown-dark-color !default;
$dropdown-dark-link-hover-color:    $white !default;
$dropdown-dark-link-hover-bg:       rgba($white, .15) !default;
$dropdown-dark-link-active-color:   $dropdown-link-active-color !default;
$dropdown-dark-link-active-bg:      $dropdown-link-active-bg !default;
$dropdown-dark-link-disabled-color: $gray-500 !default;
$dropdown-dark-header-color:        $gray-500 !default;



// Pagination
// ---------------------------------------------------------------------------------
$pagination-padding-y:              .375rem !default;
$pagination-padding-x:              .75rem !default;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;

$pagination-font-size:              $font-size-base !default;

$pagination-color:                  var(--#{$prefix}emphasis-color) !default;
$pagination-bg:                     transparent !default;
$pagination-border-radius:          var(--#{$prefix}border-radius) !default;
$pagination-border-width:           var(--#{$prefix}border-width) !default;
$pagination-margin-start:           calc(#{$pagination-border-width} * -1) !default; // stylelint-disable-line function-disallowed-list
$pagination-border-color:           var(--#{$prefix}border-color) !default;

$pagination-focus-color:            $component-active-color !default;
$pagination-focus-bg:               $component-active-bg !default;
$pagination-focus-box-shadow:       0 0 0 1px rgba(var(--#{$prefix}component-bg-rgb), .5), 0 0 .35rem .2rem rgba( var(--#{$prefix}primary-rgb), .4 ) !default;
$pagination-focus-outline:          0 !default;

$pagination-hover-color:            $component-active-color !default;
$pagination-hover-bg:               shade-color($primary, 10%) !default;
$pagination-hover-border-color:     $component-active-bg !default; // Todo in v6: remove this?
$pagination-hover-box-shadow:       0 .1rem .5rem rgba( var(--#{$prefix}primary-rgb), .5 ), 0 .25rem 1rem rgba( $black, .2 ) !default;

$pagination-active-color:           $component-active-color !default;
$pagination-active-bg:              $component-active-bg !default;
$pagination-active-border-color:    $component-active-bg !default;
$pagination-active-box-shadow:      0 0 .5rem rgba( var(--#{$prefix}primary-rgb), .5 ), 0 0 .25rem rgba( $black, .05 ) !default;

$pagination-disabled-color:         var(--#{$prefix}secondary-color) !default;
$pagination-disabled-bg:            transparent !default;
$pagination-disabled-border-color:  var(--#{$prefix}border-color) !default;

$pagination-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$pagination-border-radius-sm:       var(--#{$prefix}border-radius-sm) !default;
$pagination-border-radius-lg:       var(--#{$prefix}border-radius-lg) !default;



// Placeholders
// ---------------------------------------------------------------------------------
$placeholder-opacity-max:           .5 !default;
$placeholder-opacity-min:           .2 !default;



// Cards
// ---------------------------------------------------------------------------------
$card-spacer-y:                     $spacer + .25 !default;
$card-spacer-x:                     $spacer + .25 !default;
$card-title-spacer-y:               $spacer * .75 !default;
$card-title-color:                  var(--#{$prefix}heading-color) !default;
$card-subtitle-color:               null !default;
$card-border-width:                 var(--#{$prefix}border-width) !default;
$card-border-color:                 var(--#{$prefix}border-color) !default;
$card-border-radius:                var(--#{$prefix}border-radius) !default;
$card-box-shadow:                   var(--#{$prefix}box-shadow) !default;
$card-inner-border-radius:          subtract($card-border-radius, $card-border-width) !default;
$card-cap-padding-y:                $card-spacer-y * .75 !default;
$card-cap-padding-x:                $card-spacer-x !default;
$card-cap-bg:                       rgba(var(--#{$prefix}component-bg-rgb), .03) !default;
$card-cap-color:                    var(--#{$prefix}heading-color) !default;
$card-height:                       null !default;
$card-color:                        null !default;
$card-bg:                           var(--#{$prefix}component-bg) !default;
$card-img-overlay-padding:          $spacer !default;
$card-group-margin:                 $grid-gutter-width * .5 !default;
$card-header-height:                3.3rem !default;



// Accordion
// ---------------------------------------------------------------------------------
$accordion-padding-y:                     $spacer - .25 !default;
$accordion-padding-x:                     $spacer + .25 !default;
$accordion-color:                         var(--#{$prefix}body-color) !default;
$accordion-bg:                            var(--#{$prefix}component-bg) !default;
$accordion-border-width:                  0 !default;
$accordion-border-color:                  var(--#{$prefix}border-color) !default;
$accordion-border-radius:                 var(--#{$prefix}border-radius) !default;
$accordion-inner-border-radius:           var(--#{$prefix}border-radius) !default;

$accordion-body-padding-y:                $accordion-padding-y !default;
$accordion-body-padding-x:                $accordion-padding-x !default;

$accordion-button-padding-y:              $accordion-padding-y !default;
$accordion-button-padding-x:              $accordion-padding-x !default;
$accordion-button-color:                  var(--#{$prefix}body-color) !default;
$accordion-button-bg:                     var(--#{$prefix}accordion-bg) !default;
$accordion-transition:                    $btn-transition, border-radius .35s ease !default;
$accordion-button-active-bg:              rgba(var(--#{$prefix}body-color-rgb), .075) !default;
$accordion-button-active-color:           var(--#{$prefix}primary-text-emphasis) !default;

$accordion-button-focus-border-color:     $input-focus-border-color !default;
$accordion-button-focus-box-shadow:       none !default;

$accordion-icon-width:                    .75rem !default;
$accordion-icon-color:                    $body-color !default;
$accordion-icon-active-color:             $primary-text-emphasis !default;
$accordion-icon-transition:               transform .2s ease-in-out !default;
$accordion-icon-transform:                rotate(-180deg) !default;

$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;



// Tooltips
// ---------------------------------------------------------------------------------

$tooltip-font-size:                 $font-size-sm !default;
$tooltip-max-width:                 200px !default;
$tooltip-color:                     var(--#{$prefix}white) !default;
$tooltip-bg:                        var(--#{$prefix}black) !default;
$tooltip-border-radius:             var(--#{$prefix}border-radius) !default;
$tooltip-opacity:                   1 !default;
$tooltip-padding-y:                 $spacer * .5 !default;
$tooltip-padding-x:                 $spacer * .75 !default;
$tooltip-margin:                    null !default; // TODO: remove this in v6

$tooltip-arrow-width:               .8rem !default;
$tooltip-arrow-height:              .4rem !default;

$tooltip-arrow-color:               null !default; // Deprecated in Bootstrap 5.2.0 for CSS variables


// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y:     $tooltip-padding-y !default;
$form-feedback-tooltip-padding-x:     $tooltip-padding-x !default;
$form-feedback-tooltip-font-size:     $tooltip-font-size !default;
$form-feedback-tooltip-line-height:   null !default;
$form-feedback-tooltip-opacity:       $tooltip-opacity !default;
$form-feedback-tooltip-border-radius: $tooltip-border-radius !default;



// Popovers
// ---------------------------------------------------------------------------------
$popover-font-size:                 $font-size-sm !default;
$popover-bg:                        var(--#{$prefix}component-bg) !default;
$popover-max-width:                 276px !default;
$popover-border-width:              var(--#{$prefix}border-width) !default;
$popover-border-color:              $popover-bg !default;
$popover-border-radius:             var(--#{$prefix}border-radius-lg) !default;
$popover-inner-border-radius:       calc(#{$popover-border-radius} - #{$popover-border-width}) !default; // stylelint-disable-line function-disallowed-list
$popover-box-shadow:                var(--#{$prefix}box-shadow-lg) !default;

$popover-header-font-size:          $font-size-base !default;
$popover-header-bg:                 rgba($black, .03) !default;
$popover-header-color:              var(--#{$prefix}heading-color) !default;
$popover-header-padding-y:          .5rem !default;
$popover-header-padding-x:          $spacer !default;

$popover-body-color:                var(--#{$prefix}body-color) !default;
$popover-body-padding-y:            $spacer !default;
$popover-body-padding-x:            $spacer !default;

$popover-arrow-width:               1rem !default;
$popover-arrow-height:              .5rem !default;

// Deprecated in Bootstrap 5.2.0 for CSS variables
$popover-arrow-color:               $popover-bg !default;
$popover-arrow-outer-color:         $popover-border-color !default;



// Toasts
// ---------------------------------------------------------------------------------
$toast-max-width:                   350px !default;
$toast-padding-x:                   .75rem !default;
$toast-padding-y:                   .5rem !default;
$toast-font-size:                   .875rem !default;
$toast-color:                       null !default;
$toast-background-color:            var(--#{$prefix}component-bg) !default;
$toast-border-width:                var(--#{$prefix}border-width) !default;
$toast-border-color:                transparent !default;
$toast-border-radius:               var(--#{$prefix}border-radius) !default;
$toast-box-shadow:                  var(--#{$prefix}box-shadow-lg) !default;
$toast-spacing:                     $container-padding-x !default;

$toast-header-color:                var(--#{$prefix}heading-color) !default;
$toast-header-background-color:     $toast-background-color !default;
$toast-header-border-color:         var(--#{$prefix}border-color) !default;



// Badges
// ---------------------------------------------------------------------------------
$badge-font-size:                   .75em !default;
$badge-font-weight:                 $font-weight-bold !default;
$badge-color:                       $white !default;
$badge-padding-y:                   .5em !default;
$badge-padding-x:                   1em !default;
$badge-border-radius:               var(--#{$prefix}border-radius) !default;



// Modals
// ---------------------------------------------------------------------------------
$modal-inner-padding:               $spacer !default;

$modal-footer-margin-between:       .5rem !default;

$modal-dialog-margin:               .5rem !default;
$modal-dialog-margin-y-sm-up:       1.75rem !default;

$modal-title-line-height:           $line-height-base !default;

$modal-content-color:               null !default;
$modal-content-bg:                  var(--#{$prefix}component-bg) !default;
$modal-content-border-color:        transparent !default;
$modal-content-border-width:        var(--#{$prefix}border-width) !default;
$modal-content-border-radius:       var(--#{$prefix}border-radius-lg) !default;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs:       var(--#{$prefix}box-shadow-sm) !default;
$modal-content-box-shadow-sm-up:    var(--#{$prefix}box-shadow) !default;

$modal-backdrop-bg:                 $black !default;
$modal-backdrop-opacity:            .75 !default;

$modal-header-border-color:         var(--#{$prefix}border-color) !default;
$modal-header-border-width:         $modal-content-border-width !default;
$modal-header-padding-y:            $modal-inner-padding !default;
$modal-header-padding-x:            $modal-inner-padding !default;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-footer-bg:                   null !default;
$modal-footer-border-color:         $modal-header-border-color !default;
$modal-footer-border-width:         $modal-header-border-width !default;

$modal-sm:                          300px !default;
$modal-md:                          500px !default;
$modal-lg:                          800px !default;
$modal-xl:                          1140px !default;

$modal-fade-transform:              translate(0, -50px) !default;
$modal-show-transform:              none !default;
$modal-transition:                  transform .3s ease-out !default;
$modal-scale-transform:             scale(1.02) !default;



// Alerts
// ---------------------------------------------------------------------------------
// Define alert colors, border radius, and padding.
$alert-padding-y:               $spacer !default;
$alert-padding-x:               $spacer !default;
$alert-margin-bottom:           1rem !default;
$alert-border-radius:           var(--#{$prefix}border-radius) !default;
$alert-link-font-weight:        $font-weight-bold !default;
$alert-border-width:            0 !default;
$alert-dismissible-padding-r:   $alert-padding-x * 3 !default; // 3x covers width of x plus default padding on either side


$alert-bg-scale:                25% !default; // Deprecated in v5.2.0, to be removed in v6
$alert-border-scale:            -70% !default; // Deprecated in v5.2.0, to be removed in v6
$alert-color-scale:             40% !default; // Deprecated in v5.2.0, to be removed in v6


$alert-callout-border-width:    .25rem !default;
$alert-callout-border-radius:   0 !default;



// Progress bars
// ---------------------------------------------------------------------------------
$progress-height:                   .5rem !default;
$progress-font-size:                $font-size-base * .75 !default;
$progress-bg:                       rgba( var(--#{$prefix}emphasis-color-rgb), .075 ) !default;
$progress-border-radius:            var(--#{$prefix}border-radius) !default;
$progress-box-shadow:               var(--#{$prefix}box-shadow-inset) !default;
$progress-bar-color:                $white !default;
$progress-bar-bg:                   var(--#{$prefix}primary) !default;
$progress-bar-animation-timing:     1s linear infinite !default;
$progress-bar-transition:           width .6s ease !default;
$progress-heights: (
   "xs" : 0.0625rem,
   "sm" : 0.125rem,
   "md" : .25rem,
   "lg" : 1rem,
   "xl" : 1.25rem,
) !default;


// List group
// ---------------------------------------------------------------------------------
$list-group-color:                  var(--#{$prefix}body-color) !default;
$list-group-bg:                     transparent !default;
$list-group-border-color:           var(--#{$prefix}border-color) !default;
$list-group-border-width:           var(--#{$prefix}border-width) !default;
$list-group-border-radius:          var(--#{$prefix}border-radius) !default;

$list-group-item-padding-y:         $spacer * .5 !default;
$list-group-item-padding-x:         $spacer !default;

$list-group-item-bg-scale:          -80% !default; // Deprecated in v5.3.0
$list-group-item-color-scale:       40% !default; // Deprecated in v5.3.0

$list-group-hover-bg:               rgba(var(--#{$prefix}body-bg-rgb), .5) !default;
$list-group-active-color:           $component-active-color !default;
$list-group-active-bg:              $component-active-bg !default;
$list-group-active-border-color:    $list-group-active-bg !default;

$list-group-disabled-color:         rgba(var(--#{$prefix}secondary-color-rgb), .5) !default;
$list-group-disabled-bg:            $list-group-bg !default;

$list-group-action-color:           var(--#{$prefix}secondary-color) !default;
$list-group-action-hover-color:     var(--#{$prefix}link-hover-color) !default;

$list-group-action-active-color:    var(--#{$prefix}body-color) !default;
$list-group-action-active-bg:       var(--#{$prefix}secondary-bg) !default;



// Image thumbnails
// ---------------------------------------------------------------------------------
$thumbnail-padding:                 .25rem !default;
$thumbnail-bg:                      var(--#{$prefix}body-bg) !default;
$thumbnail-border-width:            var(--#{$prefix}border-width) !default;
$thumbnail-border-color:            var(--#{$prefix}border-color) !default;
$thumbnail-border-radius:           var(--#{$prefix}border-radius) !default;
$thumbnail-box-shadow:              var(--#{$prefix}box-shadow-sm) !default;



// Figures
// ---------------------------------------------------------------------------------
$figure-caption-font-size:          $small-font-size !default;
$figure-caption-color:              var(--#{$prefix}secondary-color) !default;



// Breadcrumbs
// ---------------------------------------------------------------------------------
$breadcrumb-font-size:              null !default;
$breadcrumb-padding-y:              0 !default;
$breadcrumb-padding-x:              0 !default;
$breadcrumb-item-padding-x:         .5rem !default;
$breadcrumb-margin-bottom:          1rem !default;
$breadcrumb-bg:                     null !default;
$breadcrumb-divider-color:          var(--#{$prefix}secondary-color) !default;
$breadcrumb-active-color:           var(--#{$prefix}emphasis-color) !default;
$breadcrumb-divider:                quote(">") !default;
$breadcrumb-divider-flipped:        $breadcrumb-divider !default;
$breadcrumb-border-radius:          null !default;
$breadcrumb-font-weight:            $font-weight-normal !default;
$breadcrumb-hover-underline:        true !default;



// Carousel
// ---------------------------------------------------------------------------------
$carousel-control-color:             $white !default;
$carousel-control-width:             15% !default;
$carousel-control-opacity:           .5 !default;
$carousel-control-hover-opacity:     .9 !default;
$carousel-control-transition:        opacity .15s ease !default;

$carousel-indicator-width:           30px !default;
$carousel-indicator-height:          3px !default;
$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-spacer:          3px !default;
$carousel-indicator-opacity:         .5 !default;
$carousel-indicator-active-bg:       $white !default;
$carousel-indicator-active-opacity:  1 !default;
$carousel-indicator-transition:      opacity .6s ease !default;

$carousel-caption-width:             70% !default;
$carousel-caption-color:             $white !default;
$carousel-caption-padding-y:         1.25rem !default;
$carousel-caption-spacer:            1.25rem !default;

$carousel-control-icon-width:        2rem !default;

$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;

$carousel-transition-duration:       .5s !default;
$carousel-transition:                transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

$carousel-dark-indicator-active-bg:  $black !default;
$carousel-dark-caption-color:        $black !default;
$carousel-dark-control-icon-filter:  invert(1) grayscale(100) !default;



// Spinners
// ---------------------------------------------------------------------------------
$spinner-width:           2rem !default;
$spinner-height:          $spinner-width !default;
$spinner-vertical-align:  -.125em !default;
$spinner-border-width:    .25em !default;
$spinner-animation-speed: .75s !default;

$spinner-width-sm:        1rem !default;
$spinner-height-sm:       $spinner-width-sm !default;
$spinner-border-width-sm: .2em !default;



// Close
// ---------------------------------------------------------------------------------
$btn-close-width:            .75em !default;
$btn-close-height:           $btn-close-width !default;
$btn-close-padding-x:        .75em !default;
$btn-close-padding-y:        $btn-close-padding-x !default;
$btn-close-color:            $black !default;
$btn-close-bg:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>") !default;
$btn-close-focus-shadow:     $focus-ring-box-shadow !default;
$btn-close-opacity:          .5 !default;
$btn-close-hover-opacity:    .95 !default;
$btn-close-focus-opacity:    1 !default;
$btn-close-disabled-opacity: .25 !default;
$btn-close-white-filter:     invert(1) grayscale(100%) brightness(200%) !default;



// Offcanvas
// ---------------------------------------------------------------------------------
$offcanvas-padding-y:               $modal-inner-padding !default;
$offcanvas-padding-x:               $modal-inner-padding !default;
$offcanvas-horizontal-width:        400px !default;
$offcanvas-vertical-height:         30vh !default;
$offcanvas-transition-duration:     .3s !default;
$offcanvas-border-color:            $modal-content-border-color !default;
$offcanvas-border-width:            $modal-content-border-width !default;
$offcanvas-title-line-height:       $modal-title-line-height !default;
$offcanvas-bg-color:                var(--#{$prefix}component-bg) !default;
$offcanvas-color:                   var(--#{$prefix}body-color) !default;
$offcanvas-box-shadow:              $modal-content-box-shadow-xs !default;
$offcanvas-backdrop-bg:             $modal-backdrop-bg !default;
$offcanvas-backdrop-opacity:        $modal-backdrop-opacity !default;



// Code
// ---------------------------------------------------------------------------------
$code-font-size:                    1em !default;
$code-color:                        $pink !default;

$kbd-padding-y:                     .1875rem !default;
$kbd-padding-x:                     .375rem !default;
$kbd-font-size:                     $code-font-size !default;
$kbd-color:                         var(--#{$prefix}body-bg) !default;
$kbd-bg:                            var(--#{$prefix}body-color) !default;
$nested-kbd-font-weight:            null !default; // Deprecated in v5.2.0, removing in v6

$pre-color:                         null !default;




// Utilities Option Values
// ---------------------------------------------------------------------------------
// Opacity
// ---------------------------------------------------------------------------------
$opacity: (
   10: .1,
   20: .2,
   25: .25,
   30: .3,
   40: .4,
   50: .5,
   60: .6,
   70: .7,
   75: .75,
   80: .8,
   90: .9,
   100: 1
) !default;



// Text Decoration Underlines
// ---------------------------------------------------------------------------------
$text-decoration-values: (
   dashed: underline dashed,
   dotted: underline dotted,
   double: underline double,
   wavy: underline wavy,
   overline: underline overline
);



// Image sizes
// ---------------------------------------------------------------------------------
$thumbnail-sizes : (
   xs: 1.75rem,
   sm: 2.25rem,
   md: 4rem,
   lg: 6rem,
   xl: 8rem,
   xxl: 16rem
) !default;



// Fixed width
// ---------------------------------------------------------------------------------
$fixed-width: (
   auto,
   100px,
   160px,
   200px,
   250px,
   300px,
   350px,
   400px,
   450px
) !default;



// Spacing
// ---------------------------------------------------------------------------------
$spacing: (
  0: 0,
  1: 5rem,
  2: 7rem,
  3: 10rem,
  4: 13rem,
  5: 15rem,
) !default;
